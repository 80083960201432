import { differenceInHours } from 'date-fns'

function getIsLoadedWithinHour(): boolean {
  if (typeof window === 'undefined') return true
  const timestampFromLs = window.localStorage.getItem('loader-anim-timestamp')
  if (!timestampFromLs) return false
  return differenceInHours(new Date(), new Date(timestampFromLs)) < 1
}

export default getIsLoadedWithinHour
