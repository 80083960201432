import { ApolloClient, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  uri: 'https://graphql.datocms.com',
  headers: {
    authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_DATOCMS_API_TOKEN}`,
    'X-Environment': `${process.env.NEXT_PUBLIC_DATOCMS_ENVIRONMENT}`,
  },
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allPressReleases: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
          allInsights: {
            keyArgs: false,
            merge(existing = [], incoming) {
              return [...existing, ...incoming]
            },
          },
        },
      },
    },
  }),
})

export default client
