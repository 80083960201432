// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://99b008414292435abcede37e30143f28@o1002703.ingest.sentry.io/6696019',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        if (breadcrumb.category.startsWith('ui')) {
          breadcrumb.message = `${hint.event.target.tagName.toLowerCase()}: ${
            hint.event.target.innerText
          }`
        }
      } catch (e) {}
      return breadcrumb
    },
  })
}
