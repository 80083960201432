import { memo, useState } from 'react'
import classNames from 'classnames'
import autoScrollToTop from '../../utils/auto-scroll-to-top'

type Props = {
  transitionStage: string
  setTransitionStage: (stage: string) => void
  onTransitionEnd: () => void
}

function SnowBall({
  transitionStage,
  setTransitionStage,
  onTransitionEnd,
}: Props) {
  const [counter, setCounter] = useState(0)

  return (
    <>
      <div
        key={counter}
        onTransitionEnd={() => {
          if (transitionStage === 'fadeOut') {
            autoScrollToTop()
            onTransitionEnd()
            setTransitionStage('fadeIn')
          }
          if (transitionStage === 'fadeIn') {
            setCounter(counter + 1)
            setTransitionStage('initial')
          }
        }}
        className={classNames(
          'fixed bottom-[100%] left-1/2 z-50 h-[max(180vw,180vh)] w-[max(180vw,180vh)] -translate-x-1/2 transform rounded-full bg-white duration-1000',
          {
            'translate-y-[0%]': transitionStage === 'initial',
            'translate-y-[200%] transition-all': transitionStage === 'fadeIn',
            'translate-y-[75%] transition-all': transitionStage === 'fadeOut',
          }
        )}
      />
    </>
  )
}

function areEqual(prev: Props, next: Props) {
  return prev.transitionStage === next.transitionStage
}

const MemoSnowBall = memo(SnowBall, areEqual)
export default MemoSnowBall
