import RystadGlobe from './rystad-globe'
import useIsomorphicLayoutEffect from '../../hooks/use-isomorphic-layout-effect'
import { useRef, useState } from 'react'
import animateTimeline from './animate-timeline'
import getIsLoadedWithinHour from './get-is-loaded-within-hour'

function Loader() {
  const scene = useRef(null)
  const [isAnimating, setIsAnimating] = useState(true)
  const tl = useRef<any>(null)
  const loadedWithinAnHour = getIsLoadedWithinHour()

  useIsomorphicLayoutEffect(() => {
    if (loadedWithinAnHour) {
      setIsAnimating(false)
      return
    }
    tl.current = animateTimeline(scene, () => {
      setIsAnimating(false)
      window.localStorage.setItem('loader-anim-timestamp', new Date().toISOString())
    })
  }, [])

  if (!isAnimating) return null

  return (
    <button
      onClick={() => {
        setIsAnimating(false)
        tl.current.progress(1)
      }}
      ref={scene}
      style={{ position: 'fixed' }} // todo this is not applied by tw class fixed :(
      className={
        'fixed relative inset-[0] z-50 flex h-screen w-screen overflow-hidden bg-transparent'
      }
    >
      <div
        className={
          'bg-layer absolute top-1/2 left-1/2 z-[-1] h-[max(180vw,180vh)] w-[max(180vw,180vh)] -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-primary-rystad-blue'
        }
      />
      <RystadGlobe />
    </button>
  )
}

export default Loader
