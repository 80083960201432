import { RefObject } from 'react'
import { gsap, Flip } from '../../lib/gsap'

function animateTimeline(scene: RefObject<HTMLDivElement>, onAnimationEnd: () => void) {
  const selector = gsap.utils.selector(scene)
  const masterTimeLine = gsap.timeline()

  const scatteredPaths = selector('.scattered-globe path')
  const alignedPaths = selector('.aligned-globe path')

  function moveOrangeDots() {
    const tl = gsap.timeline()
    const stages = [
      '.stage-0',
      '.stage-1',
      '.stage-2',
      '.stage-3',
      '.stage-4',
      '.stage-5',
    ]

    tl.to(selector('.stage-0'), {
      duration: 0.2,
      fill: 'orange',
    })

    stages.forEach((stageClass, index) => {
      const isLast = index === stages.length - 1
      if (isLast) {
        return
      }
      tl.to(selector(stageClass), {
        duration: 0.2,
        fill: 'white',
      }).to(
        selector(stages[index + 1]),
        {
          duration: 0.2,
          fill: 'orange',
        },
        '<'
      )
    })
    return tl
  }

  function moveGlobeToTopLeft(tl: any) {
    Flip.fit('.logo', '#logo-placement', {
      duration: 0.6,
      scale: true,
      ease: 'power1.inOut',
      onComplete() {
        tl.resume()
      },
    })
  }

  function curtainUp() {
    const tl = gsap.timeline()

    tl.to(selector('.bg-layer'), {
      yPercent: -150,
      duration: 1.4,
      onComplete() {
        onAnimationEnd()
      }
    })

    return tl
  }


  function showScatteredParticles() {
    const tl = gsap.timeline()

    tl.from('.scattered-globe', {
      autoAlpha: 0,
    })

    return tl
  }

  function alignScatteredGlobe(tl: any) {
    scatteredPaths.forEach((path, index) => {
      Flip.fit(path, alignedPaths[index], {
        duration: 1,
        ease: 'power1.inOut',
        onComplete() {
          if (index === scatteredPaths.length - 1) {
            tl.resume()
          }
        },
      })
    })
  }

  masterTimeLine.add(showScatteredParticles())
  masterTimeLine.addPause('>', () => alignScatteredGlobe(masterTimeLine))
  masterTimeLine.add(moveOrangeDots())
  masterTimeLine.addPause('>', () => moveGlobeToTopLeft(masterTimeLine))
  masterTimeLine.add(curtainUp())

  return masterTimeLine
}

export default animateTimeline
