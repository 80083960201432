import { useState, useEffect, ReactNode } from 'react'
import { useRouter } from 'next/router'
import SnowBall from './snow-ball'
import MemoChildren from './memo-children'

type Props = {
  children: ReactNode
}

export default function TransitionLayout({ children }: Props) {
  const router = useRouter()

  const [displayChildren, setDisplayChildren] = useState(children)
  const [transitionStage, setTransitionStage] = useState('initial')

  useEffect(() => {
    if (!router.asPath.includes('#')) {
      if (children !== displayChildren) {
        if (router.asPath.includes('?')) {
          setDisplayChildren(children)
          return
        }
        setTransitionStage('fadeOut')
      }
    }
  }, [children, displayChildren, router.asPath])

  return (
    <>
      <SnowBall
        setTransitionStage={setTransitionStage}
        transitionStage={transitionStage}
        onTransitionEnd={() => setDisplayChildren(children)}
      />

      <MemoChildren>{displayChildren}</MemoChildren>
    </>
  )
}
