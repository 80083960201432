import { createContext, ReactNode, useContext, useState } from 'react'

const useMenuVisibilityType = () => {
  const state = useState<boolean>(true)

  return state
}

type UseMenuVisibilityType = ReturnType<typeof useMenuVisibilityType>

const MenuVisibilityContext = createContext<UseMenuVisibilityType | null>(null)

const MenuVisibilityProvider = ({ children }: { children: ReactNode }) => {
  const openState = useState<boolean>(true)

  return (
    <MenuVisibilityContext.Provider value={openState}>
      {children}
    </MenuVisibilityContext.Provider>
  )
}

const useMenuVisibility = () => {
  const context = useContext(MenuVisibilityContext)!

  if (context === undefined) {
    throw new Error(
      'useMenuVisibility must be used within a MenuVisibilityProvider'
    )
  }

  return context
}

export { MenuVisibilityProvider, useMenuVisibility }
