import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { Flip } from 'gsap/dist/Flip'
import Draggable from 'gsap/dist/Draggable'
import debounce from 'lodash.debounce'

if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger)
  gsap.registerPlugin(Flip)
  gsap.registerPlugin(Draggable)

  const observer = new window.ResizeObserver(
    debounce((entries) => {
      ScrollTrigger.refresh(true)
    }, 1000)
  )

  observer.observe(document.body)
}

export * from 'gsap'
export * from 'gsap/dist/ScrollTrigger'
export * from 'gsap/dist/Flip'
export * from 'gsap/dist/Draggable'
