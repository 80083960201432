import { memo, ReactNode } from 'react'

type Props = {
  children: ReactNode
}

function Children({ children }: Props) {
  return <>{children}</>
}

export default memo(Children)
