function RystadGlobe() {
  return (
    <>
      <svg
        className={'aligned-globe absolute opacity-0'}
        width={'100%'}
        height={'100%'}
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 0 1441 798"
        fill="none"
      >
        <path
          d="M717.417 397.89C716.561 401.089 712.523 401.725 711.059 398.898C709.696 396.263 711.729 392.72 715.029 393.43C717.445 394.224 717.783 396.533 717.417 397.896"
          fill="white"
        />
        <path
          d="M711.724 404.645C711.409 406.892 709.083 407.478 707.714 405.659C706.216 403.676 707.315 400.799 709.438 401.137C709.511 401.148 709.612 401.176 709.686 401.193C711.454 401.801 711.876 403.586 711.724 404.645Z"
          fill="white"
        />
        <path
          d="M722.334 390.978C721.709 393.321 718.262 393.743 717.136 391.609C716.145 389.728 717.846 387.25 720.414 387.785C722.289 388.371 722.587 390.015 722.328 390.972"
          fill="white"
        />
        <path
          d="M726.146 386.033C725.932 387.588 723.769 387.959 722.744 386.619C721.753 385.329 722.846 383.533 724.456 383.798C724.513 383.809 724.58 383.826 724.637 383.837C725.915 384.237 726.242 385.369 726.146 386.033Z"
          fill="white"
        />
        <path
          d="M708.26 409.737C708.401 411.173 707.376 411.731 706.306 410.801C704.949 409.619 704.769 407.383 706.019 407.225C706.193 407.203 706.357 407.242 706.486 407.27C707.652 407.682 708.187 408.971 708.26 409.743"
          fill="white"
        />
        <path
          d="M706.87 413.55C707.163 414.26 707.106 414.846 706.504 414.592C705.434 414.147 704.33 412.137 704.994 411.827C705.107 411.776 705.214 411.799 705.332 411.827C706.104 412.109 706.678 413.077 706.875 413.545"
          fill="white"
        />
        <path
          d="M728.506 382.531C728.607 383.494 727.571 383.849 726.72 383.14C725.774 382.357 726.05 381.056 726.934 381.017C727.058 381.011 727.171 381.033 727.255 381.056C728.089 381.321 728.461 382.081 728.506 382.531Z"
          fill="white"
        />
        <path
          d="M729.508 380.081C729.744 380.576 729.497 380.869 729.001 380.689C728.218 380.402 727.734 379.303 728.156 379.118C728.252 379.078 728.365 379.101 728.432 379.118C728.962 379.287 729.373 379.799 729.508 380.086"
          fill="white"
        />
        <path
          d="M707.067 416.382C707.399 416.793 707.63 417.193 707.399 417.159C706.83 417.075 705.332 415.481 705.58 415.222C705.62 415.183 705.676 415.194 705.743 415.211C706.29 415.419 706.994 416.286 707.073 416.382"
          fill="white"
        />
        <path
          d="M708.424 418.495C709.004 418.945 709.015 419.002 709.015 419.024C708.807 419.081 707.202 417.909 707.23 417.723C707.247 417.712 707.252 417.712 707.286 417.723C707.54 417.825 708.108 418.253 708.424 418.5"
          fill="white"
        />
        <path
          d="M729.407 378.382C729.689 378.663 729.7 378.827 729.542 378.821C729.131 378.804 728.224 377.92 728.343 377.774C728.365 377.745 728.427 377.757 728.466 377.768C728.759 377.858 729.176 378.151 729.407 378.382Z"
          fill="white"
        />
        <path
          d="M710.395 420.027C710.8 420.241 711.014 420.357 711.037 420.376C710.936 420.393 709.573 419.678 709.415 419.531H709.432C709.492 419.554 709.813 419.719 710.395 420.027Z"
          fill="white"
        />
        <path
          d="M728.467 377.209C728.529 377.243 728.839 377.423 728.844 377.485C728.777 377.547 727.656 376.911 727.6 376.832H727.639C727.797 376.877 728.157 377.035 728.467 377.209Z"
          fill="white"
        />
        <path
          d="M709.996 395.84C709.201 398.706 706.29 399.72 704.966 397.585C703.452 395.136 705.338 390.872 708.323 391.548C710.226 392.252 710.367 394.499 709.996 395.845"
          fill="white"
        />
        <path
          d="M705.275 402.827C705.163 404.894 703.704 405.739 702.544 404.41C701.052 402.703 701.485 399.33 703.197 399.33C703.31 399.33 703.462 399.364 703.575 399.392C704.932 399.933 705.332 401.746 705.27 402.833"
          fill="white"
        />
        <path
          d="M714.979 389.035C714.101 391.198 711.246 391.772 710.412 389.953C709.618 388.219 711.567 385.544 713.83 386.034C715.357 386.552 715.351 388.117 714.974 389.041"
          fill="white"
        />
        <path
          d="M719.62 384.329C718.978 385.883 716.618 386.165 716.117 384.751C715.694 383.574 717.13 381.958 718.719 382.29C719.85 382.656 719.879 383.709 719.625 384.329"
          fill="white"
        />
        <path
          d="M703.158 408.239C703.417 409.382 703.158 410.261 702.364 409.754C701.08 408.932 700.246 405.998 701.226 405.727C701.344 405.693 701.457 405.727 701.547 405.75C702.459 406.138 703 407.552 703.158 408.239Z"
          fill="white"
        />
        <path
          d="M703.102 412.366C703.468 413.036 703.699 413.695 703.339 413.56C702.595 413.29 701.091 410.863 701.508 410.609C701.553 410.581 701.615 410.598 701.666 410.609C702.178 410.851 702.809 411.826 703.108 412.361"
          fill="white"
        />
        <path
          d="M723.213 381.135C722.92 382.165 721.388 382.261 720.898 381.49C720.42 380.735 721.276 379.558 722.419 379.795C723.207 380.042 723.325 380.729 723.213 381.135Z"
          fill="white"
        />
        <path
          d="M725.46 378.996C725.465 379.627 724.654 379.728 724.221 379.289C723.753 378.821 724.052 378.14 724.581 378.123C724.66 378.123 724.722 378.134 724.778 378.146C725.268 378.298 725.46 378.748 725.46 378.996Z"
          fill="white"
        />
        <path
          d="M704.454 415.488C705.169 416.271 705.141 416.333 705.135 416.35C704.871 416.378 703.052 414.463 703.164 414.277C703.175 414.277 703.198 414.277 703.209 414.283C703.485 414.435 704.172 415.173 704.454 415.483"
          fill="white"
        />
        <path
          d="M706.678 417.841C706.937 418.038 707.224 418.207 707.461 418.438C707.331 418.409 705.782 417.232 705.619 417.041H705.63C706.002 417.283 706.329 417.576 706.678 417.846"
          fill="white"
        />
        <path
          d="M726.445 377.576C726.597 377.869 726.406 377.993 726.141 377.903C725.696 377.745 725.426 377.16 725.668 377.058C725.719 377.036 725.786 377.053 725.82 377.058C726.107 377.143 726.355 377.413 726.44 377.576"
          fill="white"
        />
        <path
          d="M726.389 376.656C726.546 376.769 726.614 376.865 726.546 376.87C726.53 376.87 726.428 376.876 726.141 376.69C725.995 376.594 725.73 376.392 725.764 376.358C725.769 376.352 725.792 376.358 725.814 376.358C725.961 376.397 726.22 376.533 726.389 376.651"
          fill="white"
        />
        <path
          d="M714.404 411.395C714.252 412.882 712.529 413.4 711.369 411.896C710.259 410.46 710.946 408.692 712.518 408.939C712.574 408.951 712.653 408.968 712.709 408.979C714.089 409.418 714.477 410.663 714.399 411.389"
          fill="white"
        />
        <path
          d="M725.442 400.073C724.597 403.232 720.391 403.801 718.724 400.98C717.169 398.344 719.326 394.757 722.897 395.528C725.634 396.412 725.757 398.89 725.442 400.079"
          fill="white"
        />
        <path
          d="M719.191 406.623C718.549 409.022 715.339 409.287 714.134 407.04C713.092 405.097 714.523 402.653 717.091 403.199C719.129 403.846 719.461 405.604 719.191 406.623Z"
          fill="white"
        />
        <path
          d="M729.712 392.984C729.379 395.31 726.513 395.873 725.065 393.902C723.618 391.926 725.043 389.256 727.346 389.634C727.425 389.645 727.526 389.673 727.6 389.69C729.407 390.281 729.852 391.982 729.712 392.979"
          fill="white"
        />
        <path
          d="M732.358 387.752C732.46 389.244 730.995 389.796 729.79 388.726C728.416 387.504 728.822 385.466 730.1 385.387C730.28 385.375 730.444 385.415 730.579 385.443C731.795 385.849 732.313 387.048 732.358 387.752Z"
          fill="white"
        />
        <path
          d="M711.634 414.87C711.78 415.686 711.279 416.193 710.418 415.619C709.331 414.898 708.931 413.406 709.77 413.197C709.939 413.158 710.074 413.186 710.215 413.214C711.155 413.518 711.555 414.431 711.634 414.87Z"
          fill="white"
        />
        <path
          d="M733.45 383.939C733.766 384.8 733.293 385.273 732.521 384.885C731.344 384.288 730.849 382.536 731.575 382.339C731.705 382.305 731.817 382.328 731.93 382.356C732.718 382.626 733.281 383.471 733.45 383.939Z"
          fill="white"
        />
        <path
          d="M733.276 381.185C733.631 381.658 733.648 381.99 733.31 381.934C732.578 381.804 731.474 380.278 731.784 380.121C731.835 380.092 731.897 380.109 731.964 380.126C732.443 380.295 733.017 380.847 733.271 381.191"
          fill="white"
        />
        <path
          d="M710.604 417.396C710.84 417.807 710.885 418.168 710.502 418.066C709.832 417.881 708.7 416.608 709.1 416.304C709.184 416.242 709.308 416.27 709.382 416.287C709.945 416.473 710.412 417.075 710.604 417.396Z"
          fill="white"
        />
        <path
          d="M710.975 419.246C711.093 419.353 711.459 419.702 711.318 419.707C710.997 419.724 709.657 418.677 709.809 418.525C709.831 418.502 709.876 418.513 709.927 418.525C710.316 418.654 710.868 419.15 710.98 419.246"
          fill="white"
        />
        <path
          d="M732.178 379.21C732.566 379.526 732.69 379.723 732.583 379.723C732.33 379.728 730.837 378.591 730.984 378.506C730.995 378.501 731.029 378.506 731.057 378.512C731.339 378.613 731.862 378.951 732.183 379.21"
          fill="white"
        />
        <path
          d="M712.208 420.562C712.687 420.826 712.698 420.843 712.709 420.86C712.563 420.888 711.262 420.212 711.262 420.105H711.307C711.369 420.128 711.504 420.167 712.213 420.562"
          fill="white"
        />
        <path
          d="M730.454 377.8C730.708 377.936 730.978 378.093 731.001 378.138C730.894 378.155 729.48 377.367 729.469 377.355H729.497C729.66 377.412 730.083 377.598 730.454 377.795"
          fill="white"
        />
        <path
          d="M732.561 402.158C731.728 405.092 728.247 405.836 726.733 403.409C725.161 400.88 727.183 396.882 730.438 397.636C732.708 398.442 732.95 400.784 732.556 402.158"
          fill="white"
        />
        <path
          d="M726.429 408.595C725.499 410.898 722.413 411.14 721.501 408.984C720.701 407.097 722.408 404.743 724.885 405.289C726.744 405.914 726.828 407.604 726.434 408.589"
          fill="white"
        />
        <path
          d="M736.137 394.904C736.008 397.044 734.037 397.861 732.623 396.357C730.979 394.617 731.7 391.379 733.704 391.475C733.834 391.48 734.009 391.52 734.138 391.554C735.721 392.134 736.205 393.874 736.143 394.899"
          fill="white"
        />
        <path
          d="M737.652 389.391C737.939 390.765 737.122 391.469 736.092 390.737C734.712 389.757 734.273 387.11 735.467 386.964C735.607 386.947 735.742 386.98 735.849 387.009C736.891 387.408 737.494 388.653 737.652 389.396"
          fill="white"
        />
        <path
          d="M720.954 413.112C720.351 414.751 718.042 414.813 717.367 413.208C716.82 411.901 717.918 410.437 719.676 410.809C721.072 411.248 721.202 412.431 720.954 413.112Z"
          fill="white"
        />
        <path
          d="M716.916 416.259C716.747 417.25 715.497 417.521 714.77 416.456C714.134 415.527 714.658 414.508 715.688 414.677C715.728 414.682 715.784 414.699 715.823 414.705C716.77 415.003 716.995 415.797 716.916 416.265"
          fill="white"
        />
        <path
          d="M737.539 385.273C737.95 386.062 737.866 386.602 737.359 386.428C736.368 386.09 735.236 383.877 735.709 383.612C735.787 383.567 735.872 383.589 735.968 383.612C736.621 383.877 737.28 384.772 737.539 385.268"
          fill="white"
        />
        <path
          d="M736.289 382.232C736.7 382.694 736.892 383.066 736.729 383.077C736.576 383.088 736.143 382.75 735.675 382.26C735.191 381.753 734.735 381.162 734.842 381.095C734.864 381.083 734.909 381.095 734.938 381.106C735.292 381.258 735.901 381.798 736.289 382.232Z"
          fill="white"
        />
        <path
          d="M714.596 418.461C714.692 418.979 714.337 419.266 713.746 418.855C713.07 418.382 712.845 417.537 713.363 417.425C713.475 417.402 713.566 417.419 713.661 417.442C714.286 417.639 714.545 418.196 714.596 418.467"
          fill="white"
        />
        <path
          d="M713.886 420.027C714.038 420.241 714.106 420.439 713.914 420.41C713.52 420.348 712.647 419.566 712.895 419.391C712.946 419.357 713.03 419.374 713.075 419.385C713.43 419.498 713.757 419.847 713.892 420.033"
          fill="white"
        />
        <path
          d="M734.307 379.997C734.6 380.222 734.926 380.498 734.955 380.577C734.808 380.616 733.141 379.259 733.13 379.242H733.164C733.344 379.327 733.834 379.636 734.307 379.997Z"
          fill="white"
        />
        <path
          d="M714.354 421.326C714.675 421.473 714.692 421.501 714.697 421.506C714.652 421.54 714.213 421.411 714.044 421.349C713.532 421.163 713.515 421.073 713.515 421.073C713.515 421.05 713.554 421.05 713.582 421.056C713.779 421.084 714.112 421.219 714.359 421.332"
          fill="white"
        />
        <path
          d="M738.181 404.044C737.32 406.798 734.667 407.71 733.53 405.655C732.195 403.25 734.059 398.902 736.79 399.618C738.587 400.378 738.609 402.681 738.181 404.05"
          fill="white"
        />
        <path
          d="M732.397 410.391C731.242 412.491 728.697 412.874 728.145 411.033C727.604 409.231 729.525 406.742 731.62 407.248C733.135 407.828 732.915 409.445 732.397 410.391Z"
          fill="white"
        />
        <path
          d="M741.025 396.645C741.002 398.582 739.853 399.585 738.795 398.594C737.257 397.158 737.494 393.193 739.11 393.227C739.206 393.227 739.335 393.261 739.431 393.289C740.625 393.829 741.042 395.592 741.025 396.645Z"
          fill="white"
        />
        <path
          d="M741.448 390.86C741.802 392.11 741.549 392.904 740.913 392.521C739.848 391.885 738.863 388.658 739.657 388.416C739.741 388.388 739.831 388.416 739.904 388.444C740.631 388.81 741.239 390.122 741.448 390.866"
          fill="white"
        />
        <path
          d="M726.631 414.71C725.758 416.039 723.708 416.411 723.398 414.964C723.128 413.713 724.524 412.21 726.084 412.565C727.25 412.976 727.047 414.074 726.631 414.71Z"
          fill="white"
        />
        <path
          d="M721.923 417.605C721.354 418.523 719.935 418.714 719.709 417.667C719.535 416.856 720.38 415.96 721.438 416.191C722.266 416.467 722.176 417.188 721.917 417.605"
          fill="white"
        />
        <path
          d="M739.876 386.457C740.321 387.212 740.507 387.803 740.276 387.747C739.814 387.64 738.141 384.982 738.389 384.751C738.412 384.729 738.457 384.745 738.491 384.762C738.851 384.976 739.476 385.793 739.87 386.457"
          fill="white"
        />
        <path
          d="M738.018 383.146C738.423 383.591 738.761 384.013 738.773 384.075C738.592 384.002 736.818 381.986 736.818 381.969L736.841 381.98C737.015 382.11 737.528 382.605 738.018 383.146Z"
          fill="white"
        />
        <path
          d="M718.6 419.514C718.358 420.099 717.541 420.218 717.248 419.553C717.017 419.029 717.417 418.455 718.127 418.607C718.718 418.798 718.695 419.283 718.605 419.514"
          fill="white"
        />
        <path
          d="M717.147 420.881C717.276 421.174 717.034 421.388 716.617 421.213C716.229 421.05 716.105 420.712 716.387 420.577C716.432 420.555 716.477 420.549 716.516 420.543C716.882 420.515 717.091 420.752 717.147 420.881Z"
          fill="white"
        />
        <path
          d="M716.297 421.97C716.342 421.998 716.488 422.094 716.41 422.116C716.252 422.161 715.616 421.953 715.7 421.829C715.717 421.806 715.762 421.801 715.785 421.801C716.004 421.801 716.246 421.942 716.291 421.97"
          fill="white"
        />
        <path
          d="M741.667 405.628C740.912 407.841 739.392 408.883 738.767 407.616C737.899 405.865 739.561 401.134 741.306 401.72C742.331 402.339 742.072 404.445 741.667 405.628Z"
          fill="white"
        />
        <path
          d="M736.65 411.95C735.523 413.589 733.552 414.434 733.276 413.014C732.984 411.516 735.017 408.492 736.774 408.988C737.821 409.506 737.275 411.038 736.65 411.944"
          fill="white"
        />
        <path
          d="M743.362 398.04C743.373 399.752 742.917 400.867 742.399 400.388C741.458 399.51 741.379 394.762 742.303 394.678C742.354 394.678 742.421 394.7 742.472 394.723C743.108 395.196 743.351 396.964 743.356 398.04"
          fill="white"
        />
        <path
          d="M743.159 392.075C743.351 392.701 743.649 393.804 743.531 393.956C743.227 393.956 741.904 389.93 742.112 389.643C742.129 389.643 742.151 389.654 742.163 389.665C742.416 389.952 742.861 391.118 743.159 392.07"
          fill="white"
        />
        <path
          d="M730.928 416.113C729.88 417.206 728.174 417.69 728.14 416.642C728.106 415.606 729.824 413.798 731.17 414.148C732.02 414.514 731.491 415.527 730.928 416.119"
          fill="white"
        />
        <path
          d="M726.31 419.217C725.403 419.853 724.136 419.96 724.294 419.234C724.44 418.563 725.741 417.662 726.648 417.989C727.239 418.304 726.772 418.896 726.31 419.217Z"
          fill="white"
        />
        <path
          d="M722.238 420.775C721.715 421.085 720.847 421.164 720.954 420.702C721.05 420.296 721.878 419.795 722.464 420.032C722.813 420.229 722.616 420.555 722.238 420.775Z"
          fill="white"
        />
        <path
          d="M719.287 421.553C718.955 421.643 718.499 421.553 718.634 421.293C718.746 421.085 719.203 420.944 719.49 421.17C719.659 421.339 719.518 421.491 719.287 421.553Z"
          fill="white"
        />
        <path
          d="M742.963 407.253C742.355 408.959 741.685 410.074 741.533 409.64C741.223 408.768 743.087 403.451 743.633 403.654C743.65 403.66 743.661 403.677 743.678 403.688C743.915 404.178 743.38 406.087 742.963 407.253Z"
          fill="white"
        />
        <path
          d="M738.998 413.467C738.041 414.683 736.971 415.354 736.92 414.773C736.836 413.867 739.111 410.758 739.922 411.107C740.31 411.479 739.561 412.752 738.998 413.467Z"
          fill="white"
        />
        <path
          d="M744.066 401.707C744.168 400.727 744.157 397.095 744.044 396.16C744.1 396.87 744.19 397.816 744.213 398.525C744.196 399.483 744.145 400.761 744.061 401.719V401.707"
          fill="white"
        />
        <path
          d="M734.082 417.88C733.057 418.72 731.688 419.294 731.75 418.708C731.829 417.954 734.189 415.785 735.112 416.09C735.529 416.371 734.893 417.222 734.087 417.88"
          fill="white"
        />
        <path
          d="M728.979 420.578C728.106 421.017 726.991 421.265 727.087 420.91C727.194 420.51 729.171 419.266 729.914 419.536C730.235 419.75 729.677 420.229 728.985 420.578"
          fill="white"
        />
        <path
          d="M724.226 422.14C723.578 422.298 722.694 422.309 722.807 422.123C722.942 421.909 724.429 421.396 724.902 421.616C725.104 421.768 724.671 421.993 724.141 422.123"
          fill="white"
        />
        <path
          d="M720.78 422.425C720.408 422.476 719.901 422.47 719.952 422.403C720.064 422.251 721.196 422.144 721.309 422.194C721.422 422.273 721.157 422.369 720.853 422.414C720.83 422.414 720.802 422.42 720.78 422.425Z"
          fill="white"
        />
        <path
          d="M718.24 422.37C718.042 422.37 717.84 422.336 717.812 422.325C717.828 422.308 718.482 422.269 718.589 422.314C718.622 422.342 718.504 422.359 718.465 422.365C718.42 422.365 718.347 422.376 718.24 422.37Z"
          fill="white"
        />
        <path
          d="M704.054 394.036C703.237 396.744 701.215 397.938 700.393 396.198C699.363 394.03 701.142 389.378 703.333 389.941C704.69 390.578 704.397 392.898 704.054 394.036Z"
          fill="white"
        />
        <path
          d="M700.393 401.239C700.382 403.024 699.78 404.049 699.104 403.424C697.876 402.298 697.854 397.939 699.076 397.809C699.143 397.804 699.239 397.826 699.301 397.849C700.112 398.311 700.399 400.13 700.393 401.239Z"
          fill="white"
        />
        <path
          d="M708.958 387.329C707.866 389.317 705.658 390.021 705.208 388.523C704.74 386.974 706.925 384.102 708.722 384.524C709.786 384.964 709.443 386.439 708.958 387.329Z"
          fill="white"
        />
        <path
          d="M714.021 382.807C712.979 384.277 711.082 384.462 710.941 383.404C710.783 382.26 712.715 380.684 713.937 380.96C714.742 381.264 714.432 382.232 714.021 382.807Z"
          fill="white"
        />
        <path
          d="M699.633 406.967C699.931 407.97 700.066 408.905 699.751 408.741C699.075 408.392 697.847 404.647 698.349 404.478C698.377 404.467 698.422 404.484 698.45 404.495C698.901 404.816 699.419 406.252 699.633 406.967Z"
          fill="white"
        />
        <path
          d="M700.866 411.398C701.486 412.428 701.604 412.637 701.627 412.71C701.61 412.71 701.581 412.699 701.3 412.287C701.007 411.826 700.602 411.217 700.331 410.744L699.96 410.074C699.802 409.781 699.768 409.691 699.757 409.641H699.774C699.869 409.759 700.078 410.08 700.872 411.392"
          fill="white"
        />
        <path
          d="M718.347 379.85C717.631 380.779 716.252 380.881 716.139 380.194C716.021 379.467 717.395 378.487 718.262 378.679C718.831 378.881 718.623 379.49 718.347 379.85Z"
          fill="white"
        />
        <path
          d="M721.579 377.981C721.185 378.555 720.284 378.606 720.172 378.178C720.059 377.733 720.881 377.153 721.444 377.271C721.861 377.412 721.681 377.829 721.579 377.981Z"
          fill="white"
        />
        <path
          d="M723.641 376.848C723.517 377.186 722.993 377.208 722.869 376.955C722.745 376.707 723.117 376.375 723.444 376.442C723.669 376.516 723.686 376.724 723.641 376.842"
          fill="white"
        />
        <path
          d="M724.553 376.196C724.609 376.314 724.502 376.37 724.384 376.331C724.187 376.263 724.141 376.049 724.243 375.999C724.271 375.987 724.299 375.993 724.316 375.999C724.423 376.027 724.519 376.123 724.553 376.196Z"
          fill="white"
        />
        <path
          d="M699.92 392.483C699.171 394.747 698.214 396.115 697.915 395.355C697.437 394.144 699.256 388.163 700.382 388.625C700.866 389.115 700.292 391.362 699.92 392.483Z"
          fill="white"
        />
        <path
          d="M697.543 399.905C697.549 400.429 697.566 402.147 697.476 402.434C697.442 402.393 697.418 402.317 697.402 402.209C697.346 401.64 697.25 400.88 697.222 400.305C697.205 399.258 697.228 397.867 697.295 396.825C697.312 396.678 697.312 396.662 697.335 396.6L697.363 396.611C697.476 396.988 697.532 398.931 697.549 399.911"
          fill="white"
        />
        <path
          d="M704.515 385.848C703.349 387.538 701.891 388.394 701.778 387.459C701.609 386.085 704.239 382.948 705.298 383.269C705.839 383.635 705.028 385.116 704.521 385.854"
          fill="white"
        />
        <path
          d="M709.741 381.483C708.463 382.739 707.151 383.066 707.19 382.351C707.247 381.416 709.64 379.603 710.519 379.828C710.98 380.076 710.209 381.016 709.741 381.478"
          fill="white"
        />
        <path
          d="M714.568 378.742C713.543 379.502 712.518 379.683 712.563 379.232C712.625 378.652 714.534 377.582 715.215 377.745C715.632 377.942 714.579 378.736 714.568 378.742Z"
          fill="white"
        />
        <path
          d="M718.504 377.113C717.778 377.547 717.068 377.631 717.113 377.367C717.181 377.001 718.566 376.454 718.989 376.55C719.242 376.663 718.533 377.091 718.504 377.108"
          fill="white"
        />
        <path
          d="M721.388 376.235C720.886 376.477 720.503 376.477 720.543 376.353C720.599 376.168 721.461 375.909 721.714 375.965C721.838 376.021 721.433 376.213 721.388 376.235Z"
          fill="white"
        />
        <path
          d="M723.19 375.863C722.886 375.903 722.739 375.852 722.779 375.813C722.841 375.739 723.303 375.773 723.393 375.813V375.824C723.393 375.824 723.196 375.863 723.19 375.863Z"
          fill="white"
        />
        <path
          d="M702.46 384.788C701.88 385.526 700.883 386.776 700.675 386.827C700.675 386.753 700.669 386.714 700.951 386.269L701.542 385.402C702.15 384.613 702.995 383.583 703.66 382.839C703.958 382.53 704.093 382.411 704.161 382.395L704.172 382.411C704.11 382.665 702.843 384.298 702.454 384.794"
          fill="white"
        />
        <path
          d="M707.461 380.528C706.261 381.474 705.366 381.975 705.428 381.659C705.54 381.085 708.57 378.866 708.993 379.052C709.071 379.249 707.737 380.319 707.466 380.528"
          fill="white"
        />
        <path
          d="M712.416 377.936C711.222 378.567 710.591 378.724 710.603 378.561C710.625 378.201 713.25 376.95 713.683 377.091L713.694 377.108C713.672 377.266 712.709 377.784 712.416 377.936Z"
          fill="white"
        />
        <path
          d="M716.759 376.506C715.616 376.9 715.3 376.894 715.368 376.787C715.548 376.511 717.48 375.993 717.761 376.078V376.1C717.666 376.185 717.029 376.415 716.759 376.506Z"
          fill="white"
        />
        <path
          d="M720.216 375.835C719.76 375.92 719.309 376.004 719.253 375.959C719.354 375.807 720.835 375.638 720.982 375.678C721.079 375.704 720.824 375.757 720.216 375.835Z"
          fill="white"
        />
        <path
          d="M711.758 377.379C710.722 377.818 710.198 378.04 710.187 378.044C710.389 377.83 713.306 376.619 713.509 376.67C712.946 376.957 712.338 377.137 711.758 377.385"
          fill="white"
        />
        <path
          d="M716.15 376.089C715.29 376.288 714.838 376.387 714.793 376.387C714.973 376.196 717.277 375.768 717.558 375.773C717.096 375.92 716.618 375.982 716.15 376.089Z"
          fill="white"
        />
        <path
          d="M719.935 375.605C719.586 375.633 719.242 375.707 718.888 375.684C719.057 375.594 720.853 375.515 721.045 375.549C720.673 375.583 720.301 375.583 719.935 375.605Z"
          fill="white"
        />
      </svg>

      <svg
        className={'scattered-globe invisible'}
        width={'100%'}
        height={'100%'}
        preserveAspectRatio="xMidYMid slice"
        viewBox="0 0 1440 798"
        fill="none"
      >
        <g className={'logo'}>
          <path
            className={'stage-5'}
            d="M528.608 310.837C527.752 314.036 523.714 314.673 522.25 311.846C520.887 309.21 522.92 305.668 526.22 306.377C528.636 307.171 528.974 309.48 528.608 310.843"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M407.518 508.827C407.203 511.074 404.877 511.659 403.508 509.84C402.01 507.858 403.109 504.98 405.232 505.318C405.305 505.33 405.406 505.358 405.48 505.375C407.248 505.983 407.67 507.768 407.518 508.827Z"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M878.228 301.99C877.603 304.333 874.157 304.755 873.03 302.621C872.039 300.74 873.74 298.262 876.308 298.797C878.183 299.382 878.482 301.027 878.223 301.984"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M1071.43 457.592C1071.21 459.146 1069.05 459.518 1068.03 458.178C1067.04 456.888 1068.13 455.092 1069.74 455.356C1069.79 455.368 1069.86 455.384 1069.92 455.396C1071.2 455.796 1071.52 456.928 1071.43 457.592Z"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M568.092 572.538C568.233 573.974 567.208 574.531 566.138 573.602C564.781 572.42 564.601 570.184 565.851 570.026C566.025 570.004 566.189 570.043 566.318 570.071C567.484 570.482 568.019 571.772 568.092 572.544"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M572.931 492.621C573.224 493.33 573.168 493.916 572.565 493.662C571.495 493.218 570.391 491.207 571.056 490.897C571.169 490.847 571.276 490.869 571.394 490.897C572.165 491.179 572.74 492.148 572.937 492.615"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M896.636 251.07C896.738 252.033 895.702 252.388 894.851 251.679C893.905 250.896 894.181 249.595 895.065 249.556C895.189 249.55 895.302 249.572 895.386 249.595C896.22 249.86 896.591 250.62 896.636 251.07Z"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M831.263 249.717C831.499 250.213 831.252 250.506 830.756 250.326C829.973 250.038 829.489 248.94 829.911 248.754C830.007 248.715 830.12 248.737 830.187 248.754C830.717 248.923 831.128 249.436 831.263 249.723"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M508.913 554.398C509.245 554.809 509.476 555.209 509.245 555.175C508.676 555.09 507.178 553.497 507.426 553.238C507.465 553.198 507.522 553.21 507.589 553.226C508.135 553.435 508.839 554.302 508.918 554.398"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M633.023 530.907C633.603 531.357 633.615 531.414 633.615 531.436C633.406 531.493 631.801 530.321 631.829 530.135C631.846 530.124 631.852 530.124 631.886 530.135C632.139 530.237 632.708 530.665 633.023 530.913"
            fill="white"
          />
          <path
            className={'stage-5'}
            d="M786.646 213.255C786.928 213.536 786.939 213.7 786.781 213.694C786.37 213.677 785.464 212.793 785.582 212.647C785.604 212.618 785.666 212.63 785.706 212.641C785.999 212.731 786.415 213.024 786.646 213.255Z"
            fill="white"
          />
          <path
            d="M615.387 570.091C615.793 570.305 616.007 570.422 616.029 570.44C615.928 570.457 614.565 569.742 614.407 569.596H614.424C614.484 569.618 614.805 569.783 615.387 570.091Z"
            fill="white"
          />
          <path
            d="M811.715 196.422C811.777 196.456 812.087 196.636 812.092 196.698C812.025 196.76 810.904 196.124 810.848 196.045H810.887C811.045 196.09 811.405 196.248 811.715 196.422Z"
            fill="white"
          />
          <path
            d="M410.247 305.728C409.453 308.595 406.542 309.608 405.218 307.474C403.704 305.024 405.59 300.761 408.575 301.437C410.478 302.141 410.619 304.388 410.247 305.734"
            fill="white"
          />
          <path
            d="M446.4 449.646C446.288 451.712 444.829 452.557 443.669 451.228C442.177 449.522 442.61 446.148 444.322 446.148C444.435 446.148 444.587 446.182 444.7 446.21C446.057 446.751 446.457 448.564 446.395 449.651"
            fill="white"
          />
          <path
            d="M498.637 177.02C497.758 179.182 494.903 179.757 494.069 177.938C493.275 176.203 495.224 173.528 497.488 174.018C499.014 174.536 499.008 176.102 498.631 177.025"
            fill="white"
          />
          <path
            d="M734.316 220.991C733.674 222.545 731.314 222.827 730.813 221.413C730.391 220.236 731.827 218.62 733.415 218.952C734.547 219.318 734.575 220.371 734.321 220.991"
            fill="white"
          />
          <path
            d="M521.723 471.68C521.982 472.824 521.723 473.702 520.929 473.195C519.645 472.373 518.812 469.439 519.792 469.169C519.91 469.135 520.022 469.169 520.113 469.191C521.025 469.58 521.565 470.993 521.723 471.68Z"
            fill="white"
          />
          <path
            d="M495.948 508.384C496.314 509.054 496.545 509.713 496.184 509.578C495.441 509.307 493.937 506.88 494.354 506.627C494.399 506.599 494.461 506.615 494.512 506.627C495.024 506.869 495.655 507.843 495.953 508.378"
            fill="white"
          />
          <path
            d="M908.364 228.258C908.071 229.288 906.54 229.384 906.05 228.613C905.571 227.858 906.427 226.681 907.57 226.918C908.359 227.165 908.477 227.852 908.364 228.258Z"
            fill="white"
          />
          <path
            d="M812.942 160.951C812.948 161.582 812.137 161.683 811.703 161.244C811.236 160.776 811.534 160.095 812.063 160.078C812.142 160.078 812.204 160.089 812.261 160.101C812.75 160.253 812.942 160.703 812.942 160.951Z"
            fill="white"
          />
          <path
            d="M561.768 529.004C562.484 529.787 562.455 529.849 562.45 529.865C562.185 529.894 560.366 527.979 560.479 527.793C560.49 527.793 560.512 527.793 560.524 527.799C560.8 527.951 561.487 528.688 561.768 528.998"
            fill="white"
          />
          <path
            d="M602.551 535.903C602.81 536.1 603.097 536.269 603.334 536.5C603.204 536.472 601.656 535.295 601.492 535.104H601.503C601.875 535.346 602.202 535.639 602.551 535.909"
            fill="white"
          />
          <path
            d="M751.662 147.551C751.814 147.844 751.623 147.968 751.358 147.878C750.913 147.72 750.643 147.134 750.885 147.033C750.936 147.01 751.003 147.027 751.037 147.033C751.324 147.117 751.572 147.388 751.656 147.551"
            fill="white"
          />
          <path
            d="M775.094 190.574C775.252 190.687 775.319 190.783 775.252 190.788C775.235 190.788 775.133 190.794 774.846 190.608C774.7 190.512 774.435 190.31 774.469 190.276C774.474 190.27 774.497 190.276 774.519 190.276C774.666 190.315 774.925 190.451 775.094 190.569"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M550.64 432.959C550.488 434.446 548.765 434.964 547.605 433.46C546.496 432.024 547.183 430.256 548.754 430.504C548.81 430.515 548.889 430.532 548.945 430.543C550.325 430.983 550.714 432.227 550.635 432.954"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M1026.64 517.13C1025.79 520.289 1021.58 520.858 1019.92 518.036C1018.36 515.401 1020.52 511.813 1024.09 512.585C1026.83 513.469 1026.95 515.947 1026.64 517.135"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M797.962 248.844C797.32 251.243 794.11 251.507 792.904 249.26C791.863 247.317 793.293 244.873 795.861 245.42C797.9 246.067 798.232 247.824 797.962 248.844Z"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M945.563 365.668C945.231 367.994 942.364 368.557 940.917 366.586C939.47 364.609 940.894 361.94 943.198 362.317C943.277 362.329 943.378 362.357 943.451 362.374C945.259 362.965 945.704 364.666 945.563 365.662"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M995.619 263.635C995.72 265.127 994.256 265.679 993.051 264.609C991.677 263.387 992.082 261.348 993.361 261.27C993.541 261.258 993.704 261.298 993.839 261.326C995.056 261.731 995.574 262.931 995.619 263.635Z"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M601.467 492.559C601.613 493.376 601.112 493.883 600.251 493.308C599.164 492.587 598.764 491.095 599.603 490.887C599.772 490.847 599.907 490.875 600.048 490.904C600.988 491.208 601.388 492.12 601.467 492.559Z"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M908.031 310.232C908.347 311.093 907.874 311.566 907.102 311.178C905.925 310.581 905.43 308.829 906.156 308.632C906.286 308.598 906.398 308.621 906.511 308.649C907.299 308.919 907.863 309.764 908.031 310.232Z"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M966.248 173.204C966.603 173.677 966.62 174.01 966.282 173.953C965.55 173.824 964.446 172.298 964.756 172.14C964.806 172.112 964.868 172.129 964.936 172.146C965.415 172.315 965.989 172.867 966.242 173.21"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M617.616 612.93C617.853 613.341 617.898 613.701 617.515 613.6C616.845 613.414 615.713 612.141 616.113 611.837C616.197 611.775 616.321 611.803 616.394 611.82C616.957 612.006 617.425 612.609 617.616 612.93Z"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M594.556 590.238C594.674 590.345 595.04 590.694 594.899 590.7C594.578 590.716 593.238 589.669 593.39 589.517C593.412 589.494 593.457 589.506 593.508 589.517C593.897 589.646 594.449 590.142 594.561 590.238"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M830.91 209.323C831.299 209.639 831.423 209.836 831.316 209.836C831.062 209.842 829.57 208.704 829.716 208.62C829.727 208.614 829.761 208.62 829.789 208.625C830.071 208.727 830.595 209.064 830.916 209.323"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M657.54 594.739C658.019 595.004 658.03 595.021 658.041 595.038C657.895 595.066 656.594 594.39 656.594 594.283H656.639C656.701 594.306 656.836 594.345 657.545 594.739"
            fill="white"
          />
          <path
            className={'stage-4'}
            d="M836.998 165.23C837.252 165.365 837.522 165.523 837.544 165.568C837.437 165.585 836.024 164.796 836.013 164.785H836.041C836.204 164.841 836.627 165.027 836.998 165.224"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M865.511 531.738C864.678 534.673 861.198 535.416 859.683 532.989C858.112 530.46 860.133 526.462 863.388 527.216C865.658 528.022 865.9 530.364 865.506 531.738"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M1004.79 580.419C1003.86 582.723 1000.77 582.965 999.858 580.808C999.059 578.921 1000.76 576.567 1003.24 577.113C1005.1 577.739 1005.19 579.428 1004.79 580.414"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M910.338 395.32C910.208 397.46 908.237 398.277 906.823 396.773C905.179 395.033 905.9 391.795 907.905 391.891C908.034 391.896 908.209 391.936 908.338 391.97C909.921 392.55 910.405 394.29 910.343 395.315"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M916.165 277.699C916.452 279.073 915.636 279.777 914.605 279.045C913.226 278.065 912.786 275.419 913.98 275.272C914.121 275.255 914.256 275.289 914.363 275.317C915.405 275.717 916.008 276.962 916.165 277.705"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M894.159 477.231C893.556 478.87 891.247 478.932 890.572 477.327C890.025 476.02 891.124 474.556 892.881 474.928C894.277 475.367 894.407 476.55 894.159 477.231Z"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M675.946 530.226C675.777 531.217 674.527 531.487 673.801 530.423C673.164 529.494 673.688 528.475 674.719 528.644C674.758 528.649 674.814 528.666 674.854 528.672C675.8 528.97 676.025 529.764 675.946 530.232"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M950.575 330.187C950.986 330.976 950.902 331.516 950.395 331.342C949.404 331.004 948.272 328.791 948.745 328.526C948.824 328.481 948.908 328.503 949.004 328.526C949.657 328.791 950.316 329.686 950.575 330.182"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M877.806 205.627C878.217 206.088 878.408 206.46 878.245 206.471C878.093 206.483 877.659 206.145 877.192 205.655C876.708 205.148 876.252 204.557 876.359 204.489C876.381 204.478 876.426 204.489 876.454 204.5C876.809 204.652 877.417 205.193 877.806 205.627Z"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M640.082 556.225C640.177 556.743 639.822 557.03 639.231 556.619C638.555 556.146 638.33 555.301 638.848 555.188C638.961 555.166 639.051 555.183 639.147 555.205C639.772 555.402 640.031 555.96 640.082 556.23"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M669.519 561.397C669.671 561.611 669.739 561.808 669.547 561.78C669.153 561.718 668.28 560.935 668.528 560.76C668.579 560.726 668.663 560.743 668.708 560.755C669.063 560.867 669.39 561.216 669.525 561.402"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M882.685 188.829C882.978 189.054 883.304 189.33 883.332 189.409C883.186 189.448 881.519 188.091 881.508 188.074H881.542C881.722 188.159 882.212 188.468 882.685 188.829Z"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M682.853 609.217C683.174 609.363 683.191 609.391 683.196 609.397C683.151 609.431 682.712 609.301 682.543 609.239C682.031 609.053 682.014 608.963 682.014 608.963C682.014 608.941 682.053 608.941 682.081 608.946C682.278 608.975 682.611 609.11 682.858 609.222"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M984.147 520.675C983.285 523.429 980.633 524.341 979.495 522.285C978.161 519.881 980.025 515.533 982.756 516.248C984.553 517.009 984.575 519.312 984.147 520.68"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M944.938 614.309C943.784 616.409 941.238 616.792 940.686 614.951C940.145 613.149 942.066 610.659 944.161 611.166C945.676 611.746 945.456 613.363 944.938 614.309Z"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M997.172 387.501C997.15 389.438 996.001 390.44 994.942 389.449C993.405 388.013 993.641 384.048 995.258 384.082C995.353 384.082 995.483 384.116 995.579 384.144C996.772 384.685 997.189 386.448 997.172 387.501Z"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M940.433 312.421C940.788 313.671 940.534 314.465 939.898 314.082C938.833 313.446 937.848 310.219 938.642 309.977C938.726 309.948 938.817 309.977 938.89 310.005C939.616 310.371 940.225 311.683 940.433 312.426"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M815.359 525.943C814.486 527.272 812.436 527.643 812.126 526.196C811.856 524.946 813.253 523.442 814.813 523.797C815.979 524.208 815.776 525.306 815.359 525.943Z"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M800.279 585.562C799.71 586.48 798.291 586.671 798.066 585.624C797.891 584.813 798.736 583.917 799.795 584.148C800.623 584.424 800.533 585.145 800.274 585.562"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M981.216 285.963C981.661 286.718 981.846 287.309 981.616 287.253C981.154 287.146 979.481 284.488 979.729 284.257C979.752 284.234 979.797 284.251 979.83 284.268C980.191 284.482 980.816 285.299 981.21 285.963"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M935.241 238.384C935.646 238.829 935.984 239.251 935.995 239.313C935.815 239.24 934.041 237.224 934.041 237.207L934.064 237.218C934.238 237.348 934.751 237.843 935.241 238.384Z"
            fill="white"
          />
          <path
            className={'stage-3'}
            d="M714.943 604.973C714.701 605.558 713.885 605.677 713.592 605.012C713.361 604.488 713.761 603.914 714.47 604.066C715.062 604.257 715.039 604.742 714.949 604.973"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M718.347 581.899C718.477 582.192 718.234 582.406 717.818 582.231C717.429 582.068 717.305 581.73 717.587 581.595C717.632 581.572 717.677 581.567 717.716 581.561C718.082 581.533 718.291 581.769 718.347 581.899Z"
            fill="white"
          />
          <path
            className={'stage-2'}
            d="M697.32 622.874C697.366 622.902 697.512 622.998 697.433 623.02C697.275 623.066 696.639 622.857 696.724 622.733C696.74 622.711 696.785 622.705 696.808 622.705C697.028 622.705 697.27 622.846 697.315 622.874"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M932.989 472.917C932.234 475.13 930.714 476.172 930.089 474.905C929.222 473.154 930.883 468.423 932.629 469.009C933.654 469.628 933.395 471.735 932.989 472.917Z"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M923.309 568.655C922.183 570.294 920.212 571.139 919.936 569.72C919.643 568.222 921.676 565.197 923.433 565.693C924.48 566.211 923.934 567.743 923.309 568.65"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M947.677 446.6C947.689 448.312 947.232 449.427 946.714 448.949C945.774 448.07 945.695 443.323 946.619 443.238C946.669 443.238 946.737 443.261 946.787 443.283C947.424 443.756 947.666 445.525 947.672 446.6"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M1000.73 343.222C1000.92 343.847 1001.22 344.951 1001.1 345.103C1000.8 345.103 999.473 341.076 999.681 340.789C999.698 340.789 999.721 340.8 999.732 340.812C999.985 341.099 1000.43 342.265 1000.73 343.216"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M866.651 596.293C865.604 597.385 863.898 597.87 863.864 596.822C863.83 595.786 865.548 593.978 866.894 594.327C867.744 594.693 867.215 595.707 866.651 596.298"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M826.231 583.592C825.324 584.228 824.057 584.335 824.215 583.609C824.361 582.938 825.662 582.037 826.569 582.364C827.16 582.679 826.692 583.271 826.231 583.592Z"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M738.842 627.318C738.318 627.628 737.451 627.707 737.558 627.245C737.654 626.839 738.481 626.338 739.067 626.575C739.416 626.772 739.219 627.098 738.842 627.318Z"
            fill="white"
          />
          <path
            className={'stage-1'}
            d="M737.271 591.197C736.939 591.287 736.483 591.197 736.618 590.938C736.731 590.73 737.187 590.589 737.474 590.814C737.643 590.983 737.502 591.135 737.271 591.197Z"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M967.989 479.057C967.381 480.764 966.711 481.879 966.559 481.445C966.249 480.572 968.113 475.256 968.659 475.459C968.676 475.464 968.688 475.481 968.705 475.493C968.941 475.983 968.406 477.892 967.989 479.057Z"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M896.34 531.217C895.383 532.433 894.313 533.104 894.262 532.523C894.178 531.617 896.453 528.508 897.264 528.857C897.652 529.229 896.903 530.502 896.34 531.217Z"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M1043.36 407.17C1043.46 406.19 1043.45 402.558 1043.33 401.623C1043.39 402.333 1043.48 403.279 1043.5 403.988C1043.49 404.946 1043.44 406.224 1043.35 407.181V407.17"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M877.141 574.351C876.116 575.19 874.748 575.765 874.81 575.179C874.888 574.424 877.248 572.256 878.172 572.56C878.588 572.842 877.952 573.692 877.147 574.351"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M862.835 618.308C861.962 618.748 860.847 618.995 860.942 618.641C861.049 618.241 863.026 616.996 863.769 617.267C864.09 617.481 863.533 617.959 862.84 618.308"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M793.336 623.095C792.689 623.253 791.804 623.264 791.917 623.078C792.052 622.864 793.539 622.352 794.012 622.571C794.215 622.723 793.781 622.948 793.252 623.078"
            fill="white"
          />
          <path
            className={'stage-0'}
            d="M754.342 611.904C753.97 611.954 753.464 611.949 753.514 611.881C753.627 611.729 754.759 611.622 754.871 611.673C754.984 611.752 754.719 611.847 754.415 611.893C754.393 611.893 754.365 611.898 754.342 611.904Z"
            fill="white"
          />
          <path
            d="M736.239 641.048C736.041 641.048 735.839 641.014 735.811 641.003C735.827 640.986 736.481 640.947 736.588 640.992C736.621 641.02 736.503 641.037 736.464 641.042C736.419 641.042 736.346 641.054 736.239 641.048Z"
            fill="white"
          />
          <path
            d="M504.678 345.684C503.861 348.393 501.839 349.587 501.017 347.847C499.987 345.678 501.766 341.027 503.957 341.59C505.314 342.226 505.021 344.546 504.678 345.684Z"
            fill="white"
          />
          <path
            d="M502.841 402.548C502.829 404.333 502.227 405.358 501.551 404.733C500.323 403.606 500.301 399.248 501.523 399.118C501.59 399.112 501.686 399.135 501.748 399.157C502.559 399.619 502.846 401.438 502.841 402.548Z"
            fill="white"
          />
          <path
            d="M477.687 239.737C476.594 241.725 474.387 242.429 473.936 240.931C473.469 239.382 475.654 236.51 477.45 236.933C478.515 237.372 478.171 238.847 477.687 239.737Z"
            fill="white"
          />
          <path
            d="M633.004 289.135C631.962 290.605 630.064 290.791 629.923 289.732C629.765 288.589 631.697 287.012 632.919 287.288C633.724 287.592 633.415 288.56 633.004 289.135Z"
            fill="white"
          />
          <path
            d="M481.847 461.493C482.146 462.495 482.281 463.43 481.966 463.267C481.29 462.918 480.062 459.173 480.563 459.004C480.592 458.992 480.637 459.009 480.665 459.021C481.115 459.342 481.633 460.778 481.847 461.493Z"
            fill="white"
          />
          <path
            d="M532.461 525.163C533.08 526.194 533.199 526.402 533.221 526.475C533.204 526.475 533.176 526.464 532.895 526.053C532.602 525.591 532.196 524.983 531.926 524.51L531.554 523.84C531.397 523.547 531.363 523.457 531.352 523.406H531.368C531.464 523.525 531.673 523.846 532.467 525.158"
            fill="white"
          />
          <path
            d="M688.399 195.256C687.684 196.185 686.304 196.287 686.192 195.6C686.073 194.873 687.448 193.893 688.315 194.085C688.884 194.288 688.675 194.896 688.399 195.256Z"
            fill="white"
          />
          <path
            d="M708.477 182.46C708.083 183.034 707.182 183.085 707.069 182.657C706.956 182.212 707.779 181.632 708.342 181.75C708.759 181.891 708.578 182.307 708.477 182.46Z"
            fill="white"
          />
          <path
            d="M762.701 220.196C762.577 220.534 762.054 220.556 761.93 220.303C761.806 220.055 762.177 219.723 762.504 219.79C762.729 219.863 762.746 220.072 762.701 220.19"
            fill="white"
          />
          <path
            d="M752.078 199.192C752.134 199.31 752.027 199.366 751.909 199.327C751.712 199.259 751.667 199.045 751.768 198.995C751.796 198.984 751.825 198.989 751.841 198.995C751.948 199.023 752.044 199.119 752.078 199.192Z"
            fill="white"
          />
          <path
            d="M474.59 317.039C473.841 319.303 472.884 320.672 472.585 319.911C472.107 318.701 473.926 312.72 475.052 313.182C475.536 313.672 474.962 315.919 474.59 317.039Z"
            fill="white"
          />
          <path
            d="M446.727 409.321C446.732 409.845 446.749 411.563 446.659 411.85C446.625 411.809 446.601 411.733 446.586 411.625C446.53 411.056 446.434 410.296 446.406 409.721C446.389 408.674 446.411 407.283 446.479 406.241C446.496 406.094 446.496 406.078 446.518 406.016L446.547 406.027C446.659 406.404 446.716 408.347 446.732 409.327"
            fill="white"
          />
          <path
            d="M538.512 281.942C537.347 283.632 535.888 284.488 535.775 283.553C535.606 282.179 538.236 279.042 539.295 279.363C539.836 279.729 539.025 281.21 538.518 281.948"
            fill="white"
          />
          <path
            d="M591.689 270.376C590.41 271.632 589.098 271.959 589.138 271.243C589.194 270.308 591.587 268.495 592.466 268.72C592.928 268.968 592.156 269.909 591.689 270.37"
            fill="white"
          />
          <path
            d="M630.922 247.998C629.897 248.758 628.872 248.938 628.917 248.488C628.979 247.908 630.889 246.838 631.57 247.001C631.987 247.198 630.934 247.992 630.922 247.998Z"
            fill="white"
          />
          <path
            d="M669.877 166.143C669.151 166.576 668.441 166.661 668.486 166.396C668.554 166.03 669.939 165.484 670.362 165.579C670.615 165.692 669.906 166.12 669.877 166.137"
            fill="white"
          />
          <path
            d="M716.383 208.417C715.881 208.659 715.499 208.659 715.538 208.535C715.594 208.349 716.456 208.09 716.709 208.147C716.833 208.203 716.428 208.394 716.383 208.417Z"
            fill="white"
          />
          <path
            d="M733.013 196.797C732.709 196.836 732.563 196.786 732.602 196.746C732.664 196.673 733.126 196.707 733.216 196.746V196.758C733.216 196.758 733.019 196.797 733.013 196.797Z"
            fill="white"
          />
          <path
            d="M547.097 240.173C546.517 240.91 545.52 242.161 545.312 242.211C545.312 242.138 545.306 242.099 545.587 241.654L546.179 240.787C546.787 239.998 547.632 238.968 548.296 238.224C548.595 237.914 548.73 237.796 548.797 237.779L548.809 237.796C548.747 238.05 547.48 239.683 547.091 240.178"
            fill="white"
          />
          <path
            d="M577.807 245.758C576.607 246.704 575.712 247.205 575.774 246.89C575.886 246.316 578.916 244.097 579.338 244.283C579.417 244.48 578.082 245.55 577.812 245.758"
            fill="white"
          />
          <path
            d="M594.422 230.479C593.228 231.11 592.597 231.267 592.609 231.104C592.631 230.743 595.255 229.493 595.689 229.634L595.7 229.651C595.678 229.809 594.715 230.327 594.422 230.479Z"
            fill="white"
          />
          <path
            d="M656.793 237.435C655.65 237.829 655.335 237.824 655.402 237.717C655.582 237.441 657.514 236.923 657.796 237.007V237.03C657.7 237.114 657.064 237.345 656.793 237.435Z"
            fill="white"
          />
          <path
            d="M693.097 209.849C692.641 209.934 692.19 210.018 692.134 209.973C692.235 209.821 693.716 209.652 693.863 209.691C693.96 209.718 693.705 209.77 693.097 209.849Z"
            fill="white"
          />
          <path
            d="M631.863 209.86C630.827 210.299 630.303 210.52 630.292 210.524C630.495 210.31 633.412 209.099 633.615 209.15C633.051 209.437 632.443 209.617 631.863 209.865"
            fill="white"
          />
          <path
            d="M655.186 226.589C654.326 226.788 653.874 226.887 653.829 226.887C654.009 226.696 656.313 226.268 656.594 226.273C656.132 226.42 655.654 226.482 655.186 226.589Z"
            fill="white"
          />
          <path
            d="M669.044 220.537C668.694 220.565 668.351 220.638 667.996 220.616C668.165 220.526 669.962 220.447 670.153 220.481C669.781 220.514 669.41 220.514 669.044 220.537Z"
            fill="white"
          />
        </g>
      </svg>
    </>
  )
}

export default RystadGlobe
